<template>
  <div id="jobs-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/jobs" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-success mb-5">Job speichern</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/personas">Personas</router-link></li>
      <li>Connect to <router-link to="/values">Value Propositions</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
    </ul>-->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div v-if="step === 0" class="card-header">
            <h2>Einführung</h2>
            <p>
              Ein User Job, oder <i>Job to be done</i>, ist eine Aufgabe, die dein Produkt oder deine Dienstleistung erfüllt.
              Solch ein Job kann funktional sein, also einfach einen grundlegenden Zweck erfüllen, aber auch sozialer oder emotionaler Natur sein.
              Soziale und emotionale Jobs sind nicht immer auf den ersten Blick erkennbar, daher hier ein paar Beispiele:
            </p>
            <h3>Produkt: Hermés Gürtel</h3>
            <ul class="fa-ul">
              <li><span class="fa-li"><i class="fad fa-wrench"></i></span><b>Funktionaler Job:</b> Hose befestigen</li>
              <li><span class="fa-li"><i class="fad fa-users"></i></span><b>Sozialer Job:</b> Status präsentieren</li>
              <li><span class="fa-li"><i class="fad fa-heart"></i></span><b>Emotionaler Job:</b> Belohnung für einen selbst</li>
            </ul>

            <h4>Welcher Natur soll der neue Job deines Produkts sein?</h4>
            <select v-model="newJob.type" class="form-control">
              <option value="">Job-Typ auswählen</option>
              <option value="functional">Funktional</option>
              <option value="social">Sozial</option>
              <option value="emotional">Emotional</option>
            </select>

            <h4 class="mt-3">Erfüllt dein Produkt diesen Job direkt oder hilft er nur dabei?</h4>
            <div class="form-control">
              <input type="checkbox" id="job-immediate" v-model="newJob.immediate"> <label for="job-immediate">Produkt erfüllt Job direkt</label>
            </div>

            <h4 class="mt-3">Wie würdest du den Job beschreiben:</h4>
            <textarea v-model="newJob.description" class="form-control"></textarea>

            <h4 class="mt-3">Benenne den Job kurz und prägnant:</h4>
            <input type="text" v-model="newJob.title" class="form-control">

            <button @click="step++" class="btn btn-primary mt-3">Nächster Schritt</button>
          </div>
          <div v-if="step > 0" class="card-header">
            <div class="row h2">
              <div class="col-auto">
                <i class="fad fa-fw" :class="newJob.type === 'functional' ? 'fa-wrench' : newJob.type === 'social' ? 'fa-users' : 'fa-heart'"></i>
              </div>
              <div class="col">
                <input type="text" v-model="newJob.title" class="bg-transparent w-100 p-0 border-0">
              </div>
            </div>
            <select v-model="newJob.type" class="form-control lead mt-3">
              <option value="">Job-Typ auswählen</option>
              <option value="functional">Funktional</option>
              <option value="social">Sozial</option>
              <option value="emotional">Emotional</option>
            </select>
            <textarea v-model="newJob.description" class="form-control mt-3"></textarea>
            <!--<p class="mt-2 mb-0">Product immediately effects Job: {{ newJob.immediate ? "Yes" : "No" }}</p>-->
          </div>
          <div v-if="step > 0" class="card-body">
            <p class="h3">Welche Schritte muss der Nutzer durchlaufen um seinen Job zu erfüllen?</p>

            <div v-if="newJob.jobSteps.length > 0" class="scroll-container-h">
              <div class="row flex-nowrap">
                <div class="col-5">
                  <div class="card h-100">
                    <div class="card-img-top">
                      <div class="job-step-chart p-3">
                        <div class="bar bar-satisfaction" :style="'height:' + newJobStep.satisfaction / 5 * 100 + '%'"></div>
                        <div class="bar bar-potential" :style="'height:' + newJobStep.potential / 5 * 100 + '%'"></div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h4>Name des Schritts</h4>
                      <label for="">Beschreibe den Schritt kurz</label>
                      <input type="text" v-model="newJobStep.name" placeholder="Beschreibung hinzufügen..." class="form-control">
                      <h5 class="mt-3">In welcher Phase befindet sich dieser Schritt</h5>
                      <select name="" id="" v-model="newJobStep.phase" class="form-control">
                        <option disabled="disabled" value="">Phase wählen...</option>
                        <option v-for="phase in jobPhases" :value="phase" :key="phase">{{ phase }}</option>
                      </select>
                      <h5 class="mt-3">Was braucht dein Kunde um diesen Schritt abzuschließen?</h5>
                      <ul>
                        <li v-for="(need, index) in newJobStep.needs" :key="index">
                          {{ need }}
                          <i @click="removeNeed(index)" class="fal fa-minus-circle text-danger float-right"></i>
                        </li>
                        <li>
                          <input type="text" @change="addNeed($event)" placeholder="Neuen Need hinzufügen" class="form-control form-control-sm" />
                        </li>
                      </ul>
                      <h5>Metriken</h5>
                      <div class="row">
                        <div class="col-sm-6 mt-3">
                          <h6>Wie sehr beeinflusst dieser Schritt die Zufriedenheit deiner Kunden?</h6>
                          <span>{{ newJobStep.satisfaction }}</span><input type="range" v-model.number="newJobStep.satisfaction" min="1" max="5" step="1"
                                                                           class="form-control">
                        </div>
                        <div class="col-sm-6 mt-3">
                          <h6>Wieviel Potential bietet dein Webauftritt diesen Job anzusprechen?</h6>
                          <span>{{ newJobStep.potential }}</span><input type="range" v-model.number="newJobStep.potential" min="1" max="5" step="1"
                                                                        class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button @click="addJobStep" class="btn btn-primary btn-block">Job-Schritt hinzufügen</button>
                    </div>
                  </div>
                </div>
                <div v-for="(jobStep, index) in newJob.jobSteps" class="col-5" :key="index">
                  <div class="card h-100">
                    <div class="card-img-top">
                      <div class="job-step-chart p-3">
                        <div class="bar bar-satisfaction" :style="'height:' + jobStep.satisfaction / 5 * 100 + '%'"></div>
                        <div class="bar bar-potential" :style="'height:' + jobStep.potential / 5 * 100 + '%'"></div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h4 class="h5">{{ jobStep.name }}</h4>

                      <h5 class="h6">Phase:</h5>
                      <p>{{ jobStep.phase }}</p>

                      <h5>Needs:</h5>
                      <ul>
                        <li v-for="(need, index) in jobStep.needs" :key="index">{{ need }}</li>
                      </ul>
                      <h5>Metriken</h5>
                      <div class="row">
                        <div class="col-sm-6 mt-3">
                          <h6>Zufriedenheit (<span>{{ jobStep.satisfaction }}</span>)</h6>

                          <input type="range" v-model.number="jobStep.satisfaction" min="1" max="5" step="1" class="form-control">
                        </div>
                        <div class="col-sm-6 mt-3">
                          <h6>Potential (<span>{{ jobStep.potential }}</span>)</h6>
                          <input type="range" v-model.number="jobStep.potential" min="1" max="5" step="1" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button @click="removeStep(index)" class="btn btn-danger btn-block">Job-Schritt entfernen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="save" class="btn btn-success mt-3">Job speichern</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'JobsEdit',
  components: {},
  data() {
    return {
      step: 0,
      newJob: {
        id: "",
        title: "",
        type: "",
        description: "",
        competition: [],
        immediate: true,
        jobSteps: []
      },
      newJobStep: {
        name: "",
        phase: "",
        needs: [],
        satisfaction: 3,
        potential: 3,
      },
      jobPhases: [
          "Identification",
          "Planning",
          "Execution",
          "Assessment",
          "Revision",
          "Conclusion"
      ]
    }
  },
  computed: {},
  methods: {
    addJobStep() {
      this.newJob.jobSteps.push(JSON.parse(JSON.stringify(this.newJobStep)));

      this.newJobStep = {
        name: "",
        phase: "",
        needs: [],
        satisfaction: 3,
        potential: 3,
      };
    },
    addNeed($event) {
      this.newJobStep.needs.push($event.target.value);
      $event.target.value = "";
    },
    removeNeed(index) {
      this.newJobStep.needs.splice(index, 1);
    },
    removeStep(index) {
      this.newJob.jobSteps.splice(index, 1);
    },
    save() {
      if (this.$route.params.id) {
        let index = this.project.jobs.findIndex(p => p.id === this.newJob.id);
        this.project.jobs.splice(index, 1, this.newJob);
      } else {
        this.project.jobs.push(this.newJob);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'jobs', data: this.project.jobs})
          .then(res => {
            console.debug(res);
            this.$router.push("/jobs");
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newJob = this.project.jobs.find(j => j.id === this.$route.params.id);
      this.step = 1;
    } else {
      this.newJob.id = this.generateId();
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;
  width: calc(100% - 1.5rem);

  &.list-item-add {
    margin-top: 1rem;
    width: 100%;
  }
  i.fa-minus-circle {
    position: absolute;
    right: -1.5rem;
    top: .3rem;
    cursor: pointer;
  }
  &::after {
    content: "";
    display: table;
    clear: bottom;
  }
}
.job-step-chart {
  position: relative;
  height: 6rem;
  margin-top: 1rem;

  .bar {
    position: absolute;
    bottom: 0;
    width: calc(50% - 1.5rem);

    &.bar-satisfaction {
      left: 1rem;
      background: var(--primary);
      opacity: .5;
    }
    &.bar-potential {
      right: 1rem;
      background: var(--danger);
      opacity: .5;
    }
  }
}
</style>